<template>
    <form>
        <h4 class="heading text-muted mb-4">
            <!-- Upload Files &nbsp;&nbsp;&nbsp;&nbsp; -->
            <span class="text-danger">
                <i class="fa fa-info-circle"></i> Only PDF Files (Less than 800KB) are supported
            </span>            
        </h4>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <!-- import User Document File template here -->
            <DocumentFile 
                :items="items"
                :showDocuments="showDocuments"
                :showErrorStatus="showErrorStatus"
                :errorMsg="errorMsg"
                :loadCpdInfo="loadCpdInfo"
            />
        </div>

        <hr class="my-4" v-if="! isLoadingBlade"/>

        <div class="pl-lg-4" v-if="! isLoadingBlade">
            <base-button 
                type="danger" 
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i> 
                Previous
            </base-button>
            <div class="right" v-if="hasCompleteDocuments">
                <base-button 
                    type="success" 
                    @click="showNextPage()"
                >                    
                    Continue 
                    <i class="fa fa-arrow-right"></i> 
                </base-button>
            </div>            
        </div>
        
    </form>
    
</template>

<script>
    import { mapGetters } from 'vuex';
    import DocumentFile from './DocumentFile.vue';

    export default {
        components: {
            DocumentFile
        },
        name: 'Documents',
        props: ['displayConsultantData', 'displaySummary', 'documents', 'application', 'loadCpdInfo'],
        data() {
            return {
                items: {},
                showDocuments: true,
                showErrorStatus: false,
                isLoadingBlade: false,
                errorMsg: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasCompleteDocuments() {// check if all documents has been uploaded
                let status = [];
                for(let document of this.documents) {
                    status.push(document.hasFile);
                }
                let checker = arr => arr.every(v => v === true);
                return checker(status);
            }
        },
        methods: {
            setData() {
                this.items = {
                    documents: this.documents,
                    id: this.application.cpd_id
                };
            },
            showPreviousPage() {// go to next page
                return this.displayConsultantData(true);
            },
            showNextPage() {// go to next page
                return this.displaySummary(true);
            }
        },
        mounted() {
            this.setData();
        }
    }
</script>

<style>

</style>
